import * as React from "react"
import {
  Badge,
  Box,
  Button,
  Grid,
  Heading,
  Link,
  Paragraph,
  Spinner,
} from "theme-ui"
import { navigate } from "@reach/router"
import { useState } from "react"
import { useEffect } from "react"
import queryString from "query-string"
import { useQuery, useQueryClient } from "react-query"
import { useLocation } from "@reach/router"

import Seo from "../../components/seo"
import {
  fetchDiscordUser,
  fetchRazerUser,
  getDiscordToken,
  getRazerToken,
  isLoggedIn,
  logout,
  storeDiscordToken,
  storeRazerToken,
} from "../../services/auth"
import Layout from "../../components/layout"
import { assignRole } from "../../services/roles"
import { StaticImage } from "gatsby-plugin-image"

const razer_redirect_uri = process.env.RAZER_REDIRECT_URI
const discord_redirect_uri = process.env.DISCORD_REDIRECT_URI
const streamerRoleId = "735152800635289712"

const googleFormUrl = (discordId: string) =>
  `https://docs.google.com/forms/d/e/1FAIpQLSfalUXZBCvkkJV5x56RAFZ_3bwCI-K3uDvGf4pAROoXSEVOGQ/viewform?usp=pp_url&entry.1552500375=${discordId}`

const IndexPage = () => {
  const [isRazerNavigating, setRazerNavigating] = useState(false)
  const [isDiscordNavigating, setDiscordNavigating] = useState(false)
  const [razerToken, setRazerToken] = useState<string>()
  const [discordToken, setDiscordToken] = useState<string>()
  const [isAssigningRole, setAssigningRole] = useState(false)
  const queryClient = useQueryClient()
  const location = useLocation()
  const { isLoading, isSuccess, isError, data } = useQuery(
    "razerUser",
    fetchRazerUser,
    {
      enabled: !!razerToken,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  const {
    isLoading: isDiscordLoading,
    isError: isDiscordError,
    isSuccess: isDiscordSuccess,
    data: discordData,
  } = useQuery("discordUser", fetchDiscordUser, {
    enabled: !!discordToken,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const streamerHandler = () => {
    setRazerNavigating(true)
    navigate(googleFormUrl(discordData.user.id))
  }

  const razerIdHandler = () => {
    setRazerNavigating(true)
    navigate(
      `https://oauth2.razer.com/authorize_openid?response_type=code&scope=openid+profile+email&state=test&client_id=753e52ab3463152056766c244e94c91e2df66bde&redirect_uri=${razer_redirect_uri}`
    )
  }
  const discordHandler = () => {
    setDiscordNavigating(true)
    navigate(
      `https://discord.com/api/oauth2/authorize?client_id=773970020174659666&redirect_uri=${discord_redirect_uri}&response_type=code&scope=identify%20email%20guilds%20guilds.join%20guilds.members.read`
    )
  }

  const roleHandler = roleId => async () => {
    setAssigningRole(true)
    await assignRole(roleId)
    queryClient.invalidateQueries("discordUser")
    setAssigningRole(false)
  }

  useEffect(() => {
    if (getRazerToken()) {
      const code = getRazerToken()
      setRazerToken(code)
    }
    if (getDiscordToken()) {
      const discordCode = getDiscordToken()
      setDiscordToken(discordCode)
    }
  }, [])

  useEffect(() => {
    if (isError) {
      logout(() => setRazerToken(""))
    }
    if (isDiscordError) {
      setDiscordToken("")
    }
  }, [isError, isDiscordError])

  useEffect(() => {
    if (!location.search) return
    const { razer_token, discord_token } = queryString.parse(location.search)
    if (razer_token) {
      storeRazerToken(razer_token)
      setRazerToken(razer_token as string)
    }

    if (discord_token) {
      setDiscordToken(discord_token as string)
      storeDiscordToken(discord_token)
    }
  }, [location])

  const streamerRole = discordData?.currentRoles.find(
    role => role.id === streamerRoleId
  )

  return (
    <Layout>
      <Seo title="Home" />
      <Grid
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            position: "relative",
            bg: "background",
            width: "100vw",
            p: 4,
            borderRadius: 5,
            textAlign: "center",
            maxWidth: 540,
          }}
        >
          {(!razerToken || !discordToken) && (
            <>
              <Heading as="h1">Connect your Razer ID</Heading>
              <Paragraph>
                Gain access to some exclusive roles and perks by connecting your
                Razer ID to your Discord account.
              </Paragraph>

              <Button
                variant="fullWidth"
                mt={4}
                onClick={razerIdHandler}
                disabled={isRazerNavigating || !!razerToken}
              >
                {!isLoading && !isRazerNavigating ? (
                  "Login with Razer ID"
                ) : (
                  <Spinner color="white" size={14} />
                )}
              </Button>

              <Button
                variant="discord"
                mt={2}
                onClick={discordHandler}
                disabled={!razerToken || !!discordToken}
              >
                {!isDiscordLoading && !isDiscordNavigating ? (
                  "Connect with Discord"
                ) : (
                  <Spinner color="white" size={14} />
                )}
              </Button>
            </>
          )}

          {isLoading || (isDiscordLoading && <Spinner />)}

          {isSuccess && isDiscordSuccess && (
            <Box>
              <StaticImage
                src="../../images/snekiblade.png"
                placeholder="tracedSVG"
                quality={90}
                formats={["auto", "webp", "avif"]}
                alt="Vampire Sneki Snek"
                width={240}
              />
              <Heading>Discord Roles</Heading>
              {discordData.currentRoles &&
                discordData.currentRoles.map(role => (
                  <Badge
                    bg={role.color && `#${parseInt(role.color).toString(16)}`}
                  >
                    {role.name}
                  </Badge>
                ))}
              <Paragraph>
                These are the special roles are available to you:
              </Paragraph>
              {!discordData.entitledRoles.length && (
                <Paragraph sx={{ fontSize: 1 }}>
                  There are no more roles for you or all available roles have
                  been assigned.
                </Paragraph>
              )}
              {isAssigningRole ? (
                <Spinner />
              ) : (
                discordData.entitledRoles.map(role => (
                  <Box mt={2} key={role.id}>
                    <Heading color="primary" as="h4">
                      {role.name}
                    </Heading>
                    <Button
                      variant="fullWidth"
                      mt={2}
                      onClick={roleHandler(role.id)}
                    >
                      Add role
                    </Button>
                  </Box>
                ))
              )}
              <Heading mt={4}>Razer Streamer Verification</Heading>
              {(streamerRole && (
                <Paragraph>
                  You already have the{" "}
                  <Badge
                    bg={
                      streamerRole.color &&
                      `#${parseInt(streamerRole.color).toString(16)}`
                    }
                  >
                    Razer Streamer
                  </Badge>{" "}
                  role!
                </Paragraph>
              )) ?? (
                <>
                  <Paragraph>
                    Verify your Razer Streamer status to gain access to some
                    dedicated channels, as well as some exclusive opportunities
                    and rewards. Stream on!
                  </Paragraph>
                  <a href={googleFormUrl(discordData.user.id)} target="_blank">
                    <Button variant="fullWidth" mt={2}>
                      Verify (via Google Forms)
                    </Button>
                  </a>
                </>
              )}
            </Box>
          )}
          {/* <Box mt={2}>
            <Link href="/privacy" color="text" sx={{ fontSize: 0 }}>
              Privacy Policy
            </Link>
          </Box> */}
        </Box>
      </Grid>
    </Layout>
  )
}

export default IndexPage

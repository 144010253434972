import axios from "axios"

export const isBrowser = () => typeof window !== "undefined"

export const getRazerToken = () =>
  isBrowser() && window.localStorage.getItem("RazerToken")
    ? window.localStorage.getItem("RazerToken")
    : ""

export const storeRazerToken = code =>
  window.localStorage.setItem("RazerToken", code)

export const getDiscordToken = () =>
  isBrowser() && window.localStorage.getItem("DiscordToken")
    ? window.localStorage.getItem("DiscordToken")
    : ""

export const storeDiscordToken = code =>
  window.localStorage.setItem("DiscordToken", code)

export const isLoggedIn = () => {
  const code = getRazerToken()
  return !!code
}

export const logout = callback => {
  storeRazerToken("")
  storeDiscordToken("")
  callback()
}

export const fetchRazerUser = async () => {
  const token = getRazerToken()
  if (!token) return false
  const response = await axios.post("/api/razer/user", {
    token,
  })
  return response.data
}

export const fetchDiscordUser = async () => {
  const razerToken = getRazerToken()
  if (!razerToken) return false
  const token = getDiscordToken()
  if (!token) return false
  const response = await axios.post("/api/discord/user", {
    token,
    razerToken,
  })
  return response.data
}

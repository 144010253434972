import axios from "axios"
import { getDiscordToken, getRazerToken } from "./auth"

export const assignRole = async (roleId: string) => {
  const razerToken = getRazerToken()
  if (!razerToken) return false
  const discordToken = getDiscordToken()
  if (!discordToken) return false
  const response = await axios.put("/api/discord/role", {
    razerToken,
    discordToken,
    roleId,
  })
  return response.data
}
